import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Laser lithography tools`}</h4>
    <p>{`Laser lithography tools draw patterns in a substrate using laser light. They are sometimes used to produce photomasks (though less often than e-beam tools). Like other maskless lithography tools, laser lithography tools can quickly and cheaply make new patterns, but are slow at drawing patterns into wafers, making them ill-suited to mass chip production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      